import { stringify } from 'qs';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AccountUserStatus } from '@ax/api-clients-accounts/models';
import { consoleEnv } from '@ax/console-env';
import { UserFeatures } from '@ax/data-services-user/models/user';
import { RbacRole } from '@ax/data-services-authorization/models/rbac';
import { showStandardHttpErrorNotification } from '@ax/notifications';

import httpClient from './http-client';

export interface Invitation {
  create_time: string;
  email: string;
  features: UserFeatures;
  id: number;
  inviter_id: number;
  organization_id: number;
  rbac_role: RbacRole;
  token: string;
  user_id: string;
  user_status: `${AccountUserStatus}`;
}

export interface InvitationAccept {
  firstname: string;
  lastname: string;
  email: string;
  token: string;
  password: string;
}

export function findInvite(email: string, token: string): Promise<Invitation> {
  const query = stringify({ email, token });
  return httpClient
    .get(`/findInvite?${query}`, {
      baseURL: consoleEnv.CONFIG_URL,
      loaderEnabled: false,
    } as AxiosRequestConfig)
    .then(({ data }) => data);
}

export function acceptInvite(
  payload: InvitationAccept | Invitation,
): Promise<AxiosResponse<void>> {
  return httpClient
    .post<void>('/acceptInvite', payload, {
      baseURL: consoleEnv.CONFIG_URL,
      loaderEnabled: false,
    } as AxiosRequestConfig)
    .catch((error) => {
      if (error) {
        showStandardHttpErrorNotification(error);
      }
      throw error;
    });
}
