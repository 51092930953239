import { render, staticRenderFns } from "./AcceptInvite.vue?vue&type=template&id=77561cef&scoped=true&class=ax-accept-invite&"
import script from "./AcceptInvite.vue?vue&type=script&lang=ts&"
export * from "./AcceptInvite.vue?vue&type=script&lang=ts&"
import style0 from "./AcceptInvite.vue?vue&type=style&index=0&id=77561cef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77561cef",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VContainer,VImg})
